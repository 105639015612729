import Service from "../Service";

const resource = "repoclicksmasive/";

export default {
    executeMassive(obj, requestID) {
        return Service.post(resource + "executeMassive", obj, {
            params: { requestID: requestID },
        });
    },

    executeMigrated(obj, requestID) {
        return Service.post(resource + "executeMigrated", obj, {
            params: { requestID: requestID },
        });
    },

    listDetail(obj, requestID) {
        return Service.post(resource + "listSalesDetail", obj, {
            params: { requestID: requestID },
        });
    },

    listAccrued(obj, requestID) {
        return Service.post(resource + "listAccrued", obj, {
            params: { requestID: requestID },
        });
    },

    listUses(obj, requestID) {
        return Service.post(resource + "listUses", obj, {
            params: { requestID: requestID },
        });
    },


    executeMigratedSale(obj, requestID) {
        return Service.post(resource + "executeMigratedSale", obj, {
            params: { requestID: requestID },
        });
    },


    listAnalysisSale(obj, requestID) {
        return Service.post(resource + "listAnalysisSale", obj, {
            params: { requestID: requestID },
        });
    },

    getProspect(token, url, requestID) {
        return Service.post("evolta/" + "getProspect", "", {
            params: { token: token, url: url, requestID: requestID },
        });
    },

    initializeSession(requestID) {
        return Service.post("evolta/" + "getSessionEvolta", "", {
            params: { requestID: requestID },
        });
    },





};