<template>
<div>
    <v-container class="px-0">
        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Upload Data" class="tran"></s-toolbar>
                <v-card outlined>

                    <v-row style="margin-top: 0px;" class="pb-3">

                        <v-col cols="12">
                            <v-card outlined class="mx-auto my-1" width="90%">
                                <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin: auto">
                                    <v-col cols="12" lg="4" md="6">
                                        <s-date label="Periodo" v-model="date"></s-date>
                                    </v-col>
                                    <v-col cols="12" lg="12" md="12">
                                        <s-import-excel @collection="collection($event)" @loaded="loaded($event)" :headers="headersmatriz"></s-import-excel>
                                    </v-col>
                                </v-row>

                                <v-divider class="mx-4"></v-divider>

                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al cargar data <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</div>
</template>

<script>
import _sRepoClicks from '@/services/ReportClicks/ClcChargeMasive.js'
export default {

    components: {},
    data() {

        return {
            processing: false,
            messageProcessing: "",
            date: null,
            headersmatriz: [{
                    text: "Codigo de Venta",
                    value: "NumeroVenta",
                    column: "NumeroVenta"
                },
                {
                    text: "Proyecto",
                    value: "Proyecto",
                    column: "Proyecto"
                },
                {
                    text: "Nro. Inmueble",
                    value: "NroInmueble_1",
                    column: "NroInmueble_1"
                },
                {
                    text: "Area Terreno",
                    value: "AreaTerreno_1",
                    column: "AreaTerreno_1"
                },
                /* {
                    text: "FechaProforma",
                    value: "FechaProforma",
                    column: "FechaProforma",
                    date: true,
                },
                {
                    text: "FechaSepTemp_1",
                    value: "FechaSepTemp_1",
                    column: "FechaSepTemp_1",
                    date: true,
                },
                {
                    text: "FechaSepDef_1",
                    value: "FechaSepDef_1",
                    column: "FechaSepDef_1",
                    date: true,
                },
                {
                    text: "FechaVencimientoSepDef_1",
                    value: "FechaVencimientoSepDef_1",
                    column: "FechaVencimientoSepDef_1",
                    date: true,
                },
                {
                    text: "FechaVenta",
                    value: "FechaVenta",
                    column: "FechaVenta",
                    date: true,
                }, */

            ],

        };
    },

    created() {},

    watch: {},

    methods: {
        collection(collection) {

               this.$fun.alert("Seguro de Cargar Datos?", "question").then((r) => {
                if (r.value) {
                    collection.map((item) => {
                        item.UsrCreateID = this.$fun.getUserID();
                        item.Periodo = this.date
                    });

                    console.log(collection)

                    this.processing = true;
                    _sRepoClicks
                        .executeMigratedSale(collection, this.$fun.getUserID())
                        .then((r) => {
                                if (r.status == 200) {
                                    this.processing = false;
                                    this.$fun.alert("Data cargada Correctamente", "success");
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                            });

                }
            });
        },

        loaded(table) {},

    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
